exports.components = {
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-kontakt-js": () => import("./../../../src/pages/en/kontakt.js" /* webpackChunkName: "component---src-pages-en-kontakt-js" */),
  "component---src-pages-en-nova-stranka-js": () => import("./../../../src/pages/en/nova-stranka.js" /* webpackChunkName: "component---src-pages-en-nova-stranka-js" */),
  "component---src-pages-en-popis-apartmanu-js": () => import("./../../../src/pages/en/popis-apartmanu.js" /* webpackChunkName: "component---src-pages-en-popis-apartmanu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-nova-stranka-js": () => import("./../../../src/pages/nova-stranka.js" /* webpackChunkName: "component---src-pages-nova-stranka-js" */),
  "component---src-pages-popis-apartmanu-js": () => import("./../../../src/pages/popis-apartmanu.js" /* webpackChunkName: "component---src-pages-popis-apartmanu-js" */)
}

